export function getLegalEntities(params) {
  return this.$api.get('legal_entity/list', params)
    .then(({ data }) => {
      return data
    })
    .catch(error => Promise.reject(error))
}

export default {
  methods: {
    getLegalEntities(params) {
      return this.$api.get('legal_entities', params)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    getLegalEntity(id) {
      return this.$api.get('legal_entity/' + id)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    createLegalEntity(params) {
      return this.$api.post('legal_entity/0', params)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    updateLegalEntity(id, params) {
      return this.$api.post('legal_entity/' + id, params)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    }
  }
}
